import type { Zone } from '@seek/audience-zones';
import { JobDetailsView } from '@seek/job-details-view';
import { Box, ContentBlock } from 'braid-design-system';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import Lmis, { useIsNullLmis } from 'src/components/Lmis/Lmis';
import { ScreenReaderSkipTarget } from 'src/components/ScreenReaderSkipTarget/ScreenReaderSkipTarget';
import { useAppConfig } from 'src/config/appConfig';
import { HomePage } from 'src/handlers/Home';
import { SearchResultPage } from 'src/handlers/SearchResults';
import { storeLastJobId } from 'src/hooks/useLastJobId';
import type { JobDetails } from 'src/store/jobdetails/types';
import { useSelector } from 'src/store/react';
import { selectFeatureFlag } from 'src/store/selectors';

import { useSplitViewAccessibilityLinks } from '../SearchResultPage/SplitView/AccessabilityLinks/AccessabilityLinks';

import { buildJobPostingMetadata } from './buildJobPostingMetadata';
import { usePropsForJobDetailsView } from './usePropsForJobDetailsView';

interface JobDetailsPageProps {
  jobDetails: JobDetails;
  embedded?: boolean;
}

const blockAdvertisersForGFJ: string[] = ['test-id'];
const releaseZonesAndLaunchDatesForGFJ = [
  { zone: 'asia-3', launchDate: Date.parse('2025-06-11T01:00:00.000Z') }, // launchDate to be updated when confirmed
  { zone: 'asia-7' },
  { zone: 'asia-6' },
];

const validateZoneAndLaunchDate = (
  currentZone: string,
  listedAtDate: number,
) => {
  const zoneAndLaunchDate = releaseZonesAndLaunchDatesForGFJ.find(
    ({ zone: launchZone }) => currentZone === launchZone,
  );
  if (!zoneAndLaunchDate) {
    return false;
  }
  const { launchDate } = zoneAndLaunchDate;
  if (launchDate) {
    return listedAtDate >= launchDate;
  }
  return true;
};
export const shouldRenderGfj = (
  advertiserId: string,
  listedAtDate: number,
  isExpired: boolean,
  zone: Zone,
  sourceZone?: Zone,
) => {
  const disableForGFJ = blockAdvertisersForGFJ.includes(advertiserId);

  return (
    sourceZone !== null &&
    sourceZone === zone &&
    !disableForGFJ &&
    validateZoneAndLaunchDate(zone, listedAtDate) &&
    !isExpired
  );
};

const JobDetailsPage = ({ jobDetails, embedded }: JobDetailsPageProps) => {
  const props = usePropsForJobDetailsView(jobDetails);
  const isNullLmis = useIsNullLmis({});
  const { zone } = useAppConfig();

  const { advertiser, listedAt, isExpired, id, sourceZone } = jobDetails.job;

  const renderGfj = shouldRenderGfj(
    advertiser.id,
    Date.parse(String(listedAt?.dateTimeUtc)),
    isExpired,
    zone,
    sourceZone as Zone,
  );

  const jobPostedInDifferentZone = useMemo(
    () => !sourceZone || sourceZone !== zone,
    [sourceZone, zone],
  );

  const jobPostingMetadata = useMemo(
    () => buildJobPostingMetadata(jobDetails),
    [jobDetails],
  );

  // State is used here to prevent a hydration error from happening
  // Saved jobs data is not fetched server side, so we can only update the save button client side
  const [isSaved, setIsSaved] = useState(false);
  useEffect(() => {
    if (props.job.isSaved !== undefined) {
      setIsSaved(props.job.isSaved);
    }
  }, [props.job.isSaved]);

  useEffect(() => {
    storeLastJobId(id);
  }, [id]);

  const propsWithIsSaved = {
    ...props,
    job: {
      ...props.job,
      isSaved,
    },
  };

  const lmis = isNullLmis ? undefined : <Lmis />;

  const isHirerVerifiedBadgeEnable = useSelector(
    selectFeatureFlag('hirerVerifiedBadge'),
  );

  const { focusReturnToSearchLink } = useSplitViewAccessibilityLinks();
  useEffect(() => {
    focusReturnToSearchLink();
  }, [focusReturnToSearchLink]);

  const content = (
    <JobDetailsView
      {...propsWithIsSaved}
      careerInsights={lmis}
      hideJobLink={propsWithIsSaved.jobUrlOpts?.hide}
      embedded={embedded}
      featureFlags={{
        enableVerifiedHirerBadge: isHirerVerifiedBadgeEnable,
        enableReducedDuplicatedApply: true,
        stickApplyButtonOnDesktop: true,
      }}
    />
  );

  useEffect(() => {
    HomePage.preload();
    SearchResultPage.preload();
  }, []);

  return embedded ? (
    <Box
      // Prevent share button from being cropped by scroll container
      // (only an issue when the job has no cover image or logo)
      paddingTop={
        props.job.branding?.assets.cover?.url ||
        props.job.branding?.assets.logo?.url
          ? undefined
          : 'xxsmall'
      }
      data={{ automation: 'jobDetailsPage' }}
    >
      {content}
    </Box>
  ) : (
    <Box
      // Provide space from header divider on desktop
      paddingTop={{ desktop: 'large' }}
      data={{ automation: 'jobDetailsPage' }}
    >
      <ContentBlock width="medium">
        <ScreenReaderSkipTarget name="start-of-content" />
        {jobPostedInDifferentZone ? (
          <Helmet
            meta={[
              {
                name: 'robots',
                content: 'noindex,nofollow',
              },
            ]}
          />
        ) : null}
        {renderGfj ? (
          <Helmet
            script={[
              {
                type: 'application/ld+json',
                innerHTML: JSON.stringify(jobPostingMetadata),
              },
            ]}
          />
        ) : null}
        {content}
      </ContentBlock>
    </Box>
  );
};

export default JobDetailsPage;
