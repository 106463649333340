import {
  type JobDetailsView,
  useJobDetailsProps,
} from '@seek/job-details-view';

import { useSplitViewContext } from 'src/handlers/SplitViewContext';
import { useIsPDPAConsentRequired } from 'src/hooks/useIsPDPAConsentRequired';
import { useSaveJobJDV } from 'src/hooks/useSaveJob/useSaveJobJDV';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import type { JobDetails } from 'src/store/jobdetails/types';
import { useSelector } from 'src/store/react';
import {
  selectAuthenticated,
  selectEmailAddress,
  selectIsJDP,
  selectResults,
  selectJobDetailsPersonalised,
} from 'src/store/selectors';

import { useApplyTracking } from './useApplyTracking';
import useJDVTracking from './useJDVTracking';
import { useJobDetails } from './useJobDetails';
import useReportJobAd from './useReportJobAd';

type UsePropsForJobDetailsView = (
  jobDetails: JobDetails,
) => React.ComponentProps<typeof JobDetailsView>;

const useMapJobIdToJobDisplayType = (jobId: number) => {
  const jobs = useSelector(selectResults)?.jobs;
  return jobs?.find((job) => job.id === jobId)?.displayType;
};

export const usePropsForJobDetailsView: UsePropsForJobDetailsView = (
  jobDetails,
) => {
  const jobId = jobDetails.job.id;
  const email = useSelector(selectEmailAddress);
  const isAuthenticated = useSelector(selectAuthenticated);
  const jobDetailsPersonalised = useSelector(selectJobDetailsPersonalised);

  const isPDPAConsentRequired =
    useIsPDPAConsentRequired().isPDPAConsentRequired;
  const solMetadataReference = useJobDetails(jobDetails)?.solMetadataReference;
  const displayType = useMapJobIdToJobDisplayType(Number(jobId));

  const onAnalyticsEvent = useJDVTracking();
  const onReportJobAd = useReportJobAd(jobDetails?.job?.id);

  const isHirerVerified = jobDetails?.job?.advertiser?.isVerified ?? false;

  const [applyUrl, onApplyClick] = useApplyTracking({
    jobDetails,
    jobDetailsPersonalised,
    solMetadataReference,
    isHirerVerified,
  });

  const analyticsFacade = useAnalyticsFacade();
  const { selectedJobPosition } = useSplitViewContext();

  const trackVerifiedHirerBadgeClick = (linkSection: string) => {
    analyticsFacade.verifiedHirerBadgeClicked({
      jobId,
      jobListingPosition: selectedJobPosition ?? -1, // -1 is the default value when job is in standalone view
      linkSection,
    });
  };

  const trackMoreAboutThisCompanyClick = (linkSection: string) => {
    analyticsFacade.companyProfileWidgetLinkClicked({
      linkSection,
    });
  };

  const { toggleSavedJob, isSaved } = useSaveJobJDV({
    jobId: jobDetails?.job?.id,
    solMetadataReference,
  });

  const result = useJobDetailsProps({
    flags: {
      isSaved:
        jobDetailsPersonalised?.isSaved === isSaved
          ? jobDetailsPersonalised.isSaved
          : isSaved,
      appliedDateFormatted:
        jobDetailsPersonalised?.appliedDateTime?.shortAbsoluteLabel,
      isAuthenticated,
    },
    topApplicant: jobDetailsPersonalised?.topApplicantBadge,
    jobApplyUrl: applyUrl,
    jobDetails,
    salaryMatch: jobDetailsPersonalised?.salaryMatch,
  });

  const isJobDetailsStandalone = useSelector(selectIsJDP);

  return {
    isPDPAConsentRequired,
    job: result.job,
    ctas: {
      onApplyClick,
      onToggleSaveJob: toggleSavedJob,
      onReportJobAd,
      trackVerifiedHirerBadgeClick,
      trackMoreAboutThisCompanyClick,
    },
    reportJobAdInitialValues: {
      email,
      reason: '',
      comment: '',
    },
    onAnalyticsEvent,
    uniqueContainerValue: 'job-details-page',
    shouldOpenApplyInNewTab: !isJobDetailsStandalone,
    // This should be removed in metropolis after
    // https://myseek.atlassian.net/browse/DCS-9549
    hideOpenInNewTab: true,
    displayCompanyProfile: true,
    renderRelativePaths: true,
    jobUrlOpts: {
      hide: isJobDetailsStandalone,
      params: {
        ref: 'search-standalone',
        type: displayType,
        origin: true,
      },
      meta: {
        solMetadataReference,
      },
    },
  };
};
