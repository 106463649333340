import type { SearchResultJob, SearchResultJobV5 } from '@seek/chalice-types';
import { Box, Stack } from 'braid-design-system';
import type { ComponentProps } from 'react';

import { ModifyMySearchAccessabilityLink } from 'src/components/SearchResultPage/SplitView/AccessabilityLinks/AccessabilityLinks';
import { useZoneFeatures } from 'src/config/appConfig';
import { useSelector } from 'src/store/react';
import {
  selectCanShowNudges,
  selectCanonicalCompany,
  selectClearCompanyRefinements,
  selectIsRadialFilterNudgeShown,
  selectNudges,
} from 'src/store/selectors';

import CompanyResults from '../CompanyResults/CompanyResults';
import CompanySummary from '../CompanySummary/CompanySummary';

import JobListItem from './JobListItem/JobListItem';
import DistanceFromNudge from './Nudges/DistanceFromNudge/DistanceFromNudge';
import ProfileNudge from './Nudges/ProfileNudge/ProfileNudge';

interface SearchResultListProps
  extends Pick<ComponentProps<typeof JobListItem>, 'setCurrentJob'> {
  jobs: SearchResultJob[] | SearchResultJobV5[];
  isCompanySearch: boolean;
  selectedJobId?: number;
}

const isFeatured = (job: SearchResultJob | SearchResultJobV5) => {
  if ('isPremium' in job) {
    // This is a V4 job
    return job.isPremium;
  } else if ('isFeatured' in job) {
    // This is a V5 job
    return job.isFeatured;
  }

  return false;
};
const getIndexOfFirstNonFeatured = (
  jobs: SearchResultJob[] | SearchResultJobV5[],
): number => jobs.findIndex((job) => !isFeatured(job));

const SearchResultList = ({
  jobs = [],
  setCurrentJob,
  selectedJobId,
  isCompanySearch,
}: SearchResultListProps) => {
  const { NUDGE_ENABLED } = useZoneFeatures();
  const canShowNudge = useSelector(selectCanShowNudges);
  const shouldShowProfileNudge = useSelector(selectNudges).length > 0;
  const shouldShowDistanceFromNudge = useSelector(
    selectIsRadialFilterNudgeShown,
  );
  const canonicalCompany = useSelector(selectCanonicalCompany);
  const clearCompanyRefinements =
    useSelector(selectClearCompanyRefinements) || false;
  const companyName = canonicalCompany?.description;

  const profileNudgePosition = getIndexOfFirstNonFeatured(jobs) + 1;

  const jobList = jobs.map((job, index) => (
    <JobListItem
      position={index + 1}
      key={`${job.id}-${job.displayType}`}
      job={job}
      solMetadata={job.solMetadata}
      setCurrentJob={setCurrentJob}
      isSelected={selectedJobId === job.id}
    />
  ));

  const distanceFromNudgePosition = Math.floor(jobList.length / 2);

  if (NUDGE_ENABLED && shouldShowProfileNudge && canShowNudge) {
    jobList.splice(
      profileNudgePosition,
      0,
      <ProfileNudge key="ProfileNudge" position={profileNudgePosition} />,
    );
  }

  if (shouldShowDistanceFromNudge) {
    jobList.splice(
      distanceFromNudgePosition,
      0,
      <DistanceFromNudge
        key="DistanceFromNudge"
        position={distanceFromNudgePosition}
      />,
    );
  }

  return (
    <>
      {isCompanySearch && companyName ? (
        <Box paddingBottom="medium">
          <Stack space="small">
            <CompanySummary companyName={companyName} />
            {typeof clearCompanyRefinements === 'object' ? (
              <CompanyResults
                isCompanySearch={isCompanySearch}
                clearCompanyRefinements={clearCompanyRefinements}
                jobs={jobs || []}
              />
            ) : null}
          </Stack>
        </Box>
      ) : null}

      <ModifyMySearchAccessabilityLink placement="job-list" position="top" />

      <Stack space="xsmall">{jobList}</Stack>

      <ModifyMySearchAccessabilityLink placement="job-list" position="bottom" />
    </>
  );
};

export default SearchResultList;
