import { useTranslations } from '@vocab/react';

import { IllustratedError } from 'src/components/IllustratedError/IllustratedError';
import BriefcaseSvg from 'src/components/JobDetailsPage/Briefcase.svg';

import translations from './.vocab';

export const JobExpired = () => {
  const { t } = useTranslations(translations);

  return (
    <IllustratedError
      heading={t('This job is no longer available')}
      description={t('Select another job or try a different search.')}
      Illustration={BriefcaseSvg}
      dataAutomation="JDVExpired"
    />
  );
};
